<!-- @format -->

<template>
	<div>
		<layout-default-new>
			<div><h2>Get Purchase Invoices</h2></div>

			<div><b-button @click="refetch">Refetch</b-button></div>
		</layout-default-new>
	</div>
</template>

<script>
import Store from '@/store'
import { reactive } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'

import GetSumOfPurchaseInvoicesByInvoiceTypeQuery from '@/_srcv2/pages/purchase-invoice/_shared/GetSumOfPurchaseInvoicesByInvoiceTypeQuery.graphql'
import GetPurchaseInvoicesDateInterval from '@/_srcv2/pages/purchase-invoice/_shared/GetSumOfPurchaseInvoicesByInvoiceTypeQuery.graphql'
export default {
	name: 'GetPurchaseInvoicesByDateInterval',
	setup() {
		// ** ----------------------------------------------------------------------------------
		const q1Variables = reactive({
			bd: '2023-01-01',
			ed: '2023-01-31',
			our_company: Store.getters.getUserCurrentCompany,
		})
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		// ** ------------------------------------------------------------------------------------
		const {
			refetch: sumOfPurchaseInvoicesRefetch,
			onResult: sumOfPurchaseInvoicesOnResult,
		} = useQuery(
			GetSumOfPurchaseInvoicesByInvoiceTypeQuery,
			() => q1Variables,
			options,
		)
		sumOfPurchaseInvoicesOnResult((result) =>
			console.log('----- result', result),
		)
		// ** ----------------------------------------------------------------------------------
		const {
			refetch: totalsOfPurchaseInvoicesRefetch,
			onResult: totalsOfPurchaseInvoicesOnResult,
		} = useQuery(GetPurchaseInvoicesDateInterval, () => q1Variables, options)
		totalsOfPurchaseInvoicesOnResult((result) =>
			console.log('----- result', result),
		)
		// ** ----------------------------------------------------------------------------------
		const refetch = () => {
			sumOfPurchaseInvoicesRefetch()
			totalsOfPurchaseInvoicesRefetch
		}
		// ** ----------------------------------------------------------------------------------
		return { refetch }
	},
}
</script>

<style scoped></style>
